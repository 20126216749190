import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./StaffView.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import {
  blockUser,
  deleteUser,
  getUser,
  unblockUser,
} from "../../../redux/usersReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import avatar from "../../../assets/images/avatar.png";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import { convertDateFromHyphen } from "../../../utils/dateConverter/convertDateFromHyphen";
import { getCompany } from "../../../redux/companiesReducer";
import { logout } from "../../../redux/authReducer";
import { useTranslation } from "react-i18next";

// STAFF MEMBER VIEW PAGE
const StaffView = () => {
  const { t } = useTranslation();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { state } = useLocation();

  const { isTokenVerified } = useSelector((state) => state.users);

  // USE SELECTOR
  const { isLoading } = useSelector((state) => state.preloader);
  const { currentUser } = useSelector((state) => state.users);
  const { role, companyId, profileData } = useSelector((state) => state.auth);
  const { currentCompany } = useSelector((state) => state.companies);

  const isSuperUser = role === "super";
  const isUser = role === "user";

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE EFFECT
  useEffect(() => {
    if (isTokenVerified) {
      dispatch(startLoadingActionCreator());
      dispatch(getUser(state?.id || profileData?.id));
    }
  }, [state?.id, profileData?.id, isTokenVerified]);
  // IF EMPLOYEE IS CURRENTLY LOGGED IN WE GET COMPANY FROM BACKEND
  useEffect(() => {
    if (isTokenVerified && isUser) {
      dispatch(startLoadingActionCreator());
      dispatch(getCompany(companyId));
    }
  }, [isUser, isTokenVerified]);

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    ...(isSuperUser
      ? [
          {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: t("companies"),
          },
        ]
      : []),
    {
      path: `/companies/${state?.state?.company?.id || currentCompany?.id}`,
      image: state?.state?.company?.logo || currentCompany?.logo,
      current: false,
      text: state?.state?.company?.name || currentCompany?.name,
      state: { id: state?.state?.company?.id || currentCompany?.id },
    },
    {
      path: `/companies/${
        state?.state?.company?.id || currentCompany?.id
      }/staff`,
      icon: "icon-people",
      current: false,
      text: t("employees"),
      state: { company: state?.state?.company || currentCompany },
    },
    {
      path: `/companies/${
        state?.state?.company?.id || currentCompany?.id
      }/staff/${state?.id || profileData?.id}`,
      current: true,
      text: currentUser?.name,
      state: {
        id: state?.id || profileData?.id,
        state: { company: state?.state?.company || currentCompany?.id },
      },
    },
  ];
  // LINKS FOR STAFF MEMBERS
  const linksUser = [
    {
      path: "/",
      current: true,
      icon: "icon-profile-circle",
      text: t("profile"),
      state: { id: state?.id || profileData?.id },
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: state?.state?.company?.logo || currentCompany?.logo,
      text: t("company"),
      current: false,
      path: `/companies/${state?.state?.company?.id || currentCompany?.id}`,
      state: { id: state?.state?.company?.id || currentCompany?.id },
    },
    {
      icon: "icon-people",
      text: t("employees"),
      current: true,
      path: `/companies/${
        state?.state?.company?.id || currentCompany?.id
      }/staff`,
      state: { company: state?.state?.company || currentCompany },
    },
    {
      icon: "icon-money-tick",
      text: t("payments"),
      current: false,
      path: "/payments",
    },
    ...(!isSuperUser
      ? [
          {
            icon: "icon-chart",
            text: t("tariffs"),
            current: false,
            path: "/tariffs",
          },
        ]
      : []),
  ];
  // LINKS AT NAVBAR FOR USER ROLE
  const linksNavigationUser = [
    {
      icon: "icon-profile-circle",
      text: t("profile"),
      current: true,
      path: "/",
    },
  ];

  // BUTTONS ON NAVBAR FOR USER ROLE
  const buttonsNavigationUser = [
    {
      text: t("logout"),
      class: "button_error_outline",
      onClick: () => {
        dispatch(logout());
        navigate("/login");
      },
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    ...(currentUser?.is_active
      ? [
          {
            text: t("block"),
            className: "button_error_outline",
            onClick: () => {
              dispatch(blockUser(state?.id || profileData?.id));
            },
          },
        ]
      : [
          {
            text: t("unblock"),
            className: "button_transparent",
            onClick: () => {
              dispatch(unblockUser(state?.id || profileData?.id));
            },
          },
        ]),
    {
      text: t("delete"),
      className: "button_error",
      onClick: () => {
        dispatch(deleteUser(state?.id || profileData?.id)).then((response) => {
          navigate(
            `/companies/${state?.state?.company?.id || currentCompany?.id}`,
            { state: { id: state?.state?.company?.id || currentCompany?.id } }
          );
        });
      },
    },
    {
      text: t("editing"),
      icon: "icon-edit-2",
      className: "button_gradient",
      onClick: () => {
        navigate(
          `/companies/${
            state?.state?.company?.id || currentCompany?.id
          }/staff/${state?.id || profileData?.id}/edit`,
          {
            state: {
              staff: { id: state?.id || profileData?.id },
              company: state?.state?.company || currentCompany,
            },
          }
        );
      },
    },
  ];
  // BUTTONS ON HEADER FOR USER ROLE
  const buttonsHeaderUser = [
    {
      text: t("editing"),
      icon: "icon-edit-2",
      className: "button_gradient",
      onClick: () => {
        navigate("/edit", {
          state: { staff: { id: profileData?.id }, company: currentCompany },
        });
      },
    },
  ];

  // GENDER OBJECT TO DISPLAY GENDER OF STAFF MEMBER
  const gender = {
    male: t("male"),
    female: t("female"),
  };

  // ROLE OBJECT TO DISPLAY ROLE OF STAFF MEMBER
  const roleObject = {
    super: t("platformAdmin"),
    admin: t("companyAdmin"),
    moderator: t("companyManager"),
    user: t("companyEmployee"),
  };

  // DATA TO DISPLAY IN INFO BLOCK
  const personalDataValues = [
    {
      label: t("name"),
      value: currentUser?.name,
    },
    {
      label: t("birthDate"),
      value: currentUser?.date_of_birth
        ? convertDateFromHyphen(currentUser?.date_of_birth)
        : "-",
    },
    {
      label: t("sex"),
      value: gender[currentUser?.gender] || "-",
    },
    {
      label: t("email"),
      value: currentUser?.email,
    },
    {
      label: t("phoneNumber"),
      value: currentUser?.phone || "-",
    },
  ];
  const companyValues = [
    {
      label: t("companyName"),
      value: state?.state?.company?.name || currentCompany?.name,
    },
    {
      label: t("department"),
      value: "-",
    },
    {
      label: t("role"),
      value: roleObject[currentUser?.role],
    },
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.view}`}>
      <BreadCrumbs links={isUser ? linksUser : links} />
      <div className={`flex ${style.viewMain}`}>
        <NavigationBar
          links={isUser ? linksNavigationUser : linksNavigation}
          buttons={isUser ? buttonsNavigationUser : []}
        />
        <div className={`flex__column ${style.viewMainData}`}>
          <CRUDHeader
            image={avatar}
            name={currentUser?.name}
            buttons={isUser ? buttonsHeaderUser : buttonsHeader}
          />
          <InfoBlock values={personalDataValues} heading={t("personalData")} />
          <InfoBlock values={companyValues} heading={t("companyData")} />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default StaffView;
