import style from "./Companies.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoadingActionCreator,
  stopLoadingActionCreator,
} from "../../redux/preloaderReducer";
import Preloader from "../../components/UI/Preloader/Preloader";
import BreadCrumbs from "../../components/functional/BreadCrumbs/BreadCrumbs";
import TableComponent from "../../components/functional/Table/Table";
import { useTranslation } from "react-i18next";

// COMPANIES PAGE
const Companies = ({
  getCompanies,
  getUsers,
  usersTotal,
  companiesTotal,
  users,
  companies,
}) => {
  const { t } = useTranslation();

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE SELECTOR
  const { isLoading } = useSelector((state) => state.preloader);

  // ROWS OF A TABLE
  const [rows, setRows] = useState([]);

  // USE EFFECT
  useEffect(() => {
    dispatch(startLoadingActionCreator());
    getCompanies();
  }, [getCompanies]);
  useEffect(() => {
    if (companies.length) {
      dispatch(stopLoadingActionCreator());
      const companiesRows = companies?.map((company) => {
        return {
          id: company.id,
          name: company.name,
          users: company.users.length,
          createdTests: "-",
          passedTests: "-",
          subscription: company.active ? t("active") : t("inactive"),
          lastPaymentDate: "-",
          icon: "icon-dots",
        };
      });
      setRows(companiesRows);
    }
  }, [companies.length]);

  // GETTING THE ROLE OF THE CURRENT USER
  const { role } = useSelector((state) => state.auth);

  // IS IT SUPER USER?
  const isSuperUser = role === "super";

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    {
      path: "/companies",
      icon: "icon-buildings-2",
      current: true,
      text: t("companies"),
    },
  ];

  // COLUMNS OF A TABLE
  const columns = [
    t("legalName"),
    t("employees"),
    t("createdTests"),
    t("testsSolved"),
    t("subscription"),
    t("lastPayment"),
    "icon-dots",
  ];

  if (isLoading) return <Preloader />;

  return (
    <div className={`flex__column ${style.companies}`}>
      <BreadCrumbs links={links} />
      <TableComponent
        columns={columns}
        rows={rows}
        entity="companies"
        headerText={t("companies")}
        headerButtonText={t("addCompany")}
      />
    </div>
  );
};

// EXPORT
export default Companies;
