import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./TestsView.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTest, getTest } from "../../../redux/testsReducer";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import QuestionBlock from "../QuestionBlock";
import { formatDuration } from "../../../utils/durationConverter/durationConverter";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import { useTranslation } from "react-i18next";

// TEST VIEW PAGE
const TestsView = () => {
  const { t } = useTranslation();

  // USE LOCATION
  const { state } = useLocation();

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE SELECTOR
  const testsState = useSelector((state) => state.tests);
  const { currentTest } = testsState;
  const { isLoading } = useSelector((state) => state.preloader);

  // DATA TO DISPLAY IN INFO BLOCK
  const values = [
    {
      label: t("testName"),
      value: currentTest?.name,
    },
    {
      label: t("category"),
      value: currentTest?.type[0].toUpperCase() + currentTest?.type.slice(1),
    },
    {
      label: t("company"),
      value: currentTest?.company_name || t("noCompany"),
    },
    {
      label: t("dateCreated"),
      value: currentTest?.created_at,
    },
    {
      label: t("duration"),
      value: currentTest?.time_allocated
        ? formatDuration(currentTest?.time_allocated)
        : t("noLimit"),
    },
    {
      label: t("questionsNumber"),
      value: currentTest?.questions.length,
    },
  ];

  // USE EFFECT
  useEffect(() => {
    if (state?.id) {
      dispatch(startLoadingActionCreator());
      dispatch(getTest(state.id));
    }
  }, [state?.id]);

  const links = [
    {
      path: "/tests",
      icon: "icon-document-normal",
      current: false,
      text: t("testingCenter"),
    },
    {
      path: `/tests/${state.id}`,
      image: currentTest?.cover_image,
      current: true,
      text: currentTest?.name,
      state: { id: state.id },
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      icon: "icon-document-text",
      text: t("assignment"),
      current: false,
      path: "/tests/assign",
    },
    {
      icon: "icon-document-normal",
      text: t("tests"),
      current: true,
      path: "/tests",
    },
    {
      icon: "icon-clipboard-tick",
      text: t("results"),
      current: false,
      path: "/results",
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("delete"),
      className: "button_error",
      onClick: () => {
        dispatch(deleteTest(state?.id, navigate));
      },
    },
    {
      text: t("editing"),
      className: "button_gradient",
      icon: "icon-edit-2",
      onClick: () => {
        navigate(`/tests/edit/${state?.id}`, {
          state: { test: { id: state?.id } },
        });
      },
    },
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.view}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.viewMain}`}>
        <NavigationBar links={linksNavigation} buttons={[]} />
        <div className={`flex__column ${style.viewMainData}`}>
          <CRUDHeader
            image={currentTest?.cover_image}
            name={currentTest?.name}
            buttons={buttonsHeader}
          />
          <InfoBlock values={values} heading={t("testData")} />
          <div className={`flex__column ${style.viewMainDataQuestions}`}>
            {currentTest?.questions?.length
              ? currentTest?.questions.map((question, index) => {
                  return (
                    <QuestionBlock
                      key={index}
                      questionNumber={index + 1}
                      question={question.text}
                      options={question.choices}
                      index={index}
                      isEditMode={false}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default TestsView;
