import axios from "axios";

// CREATING AXIOS INSTANCE
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
});

// Function to get the JWT token from storage (e.g., localStorage)
const getToken = () => localStorage.getItem("token");

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
