import { userGroupsAPI } from "../api/api";
import { successToast } from "../utils/toasts/toasts";
import { startLoadingActionCreator, stopComponentLoadingActionCreator, stopLoadingActionCreator } from "./preloaderReducer";

// ACTION TYPES
const CREATE_USER_GROUP = "CREATE_USER_GROUP";
const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
const SET_USER_GROUPS = "SET_USER_GROUPS";
const SET_USERS = "SET_USERS";
const SET_USERS_SEARCH = "SET_USERS_SEARCH";
const CLEAR_USERS_SEARCH = "CLEAR_USERS_SEARCH";
const DELETE_USER_FROM_USERGROUP = "DELETE_USER_FROM_USERGROUP";
const SET_SEARCHED_USERGROUPS = "SET_SEARCHED_USERGROUPS";
const SET_GROUPS_SEARCH_TEXT = "SET_SEARCH_TEXT";
const SET_CHOSEN_USERGROUPS = "SET_CHOSEN_USERGROUPS";
const REMOVE_CHOSEN_USERGROUP = "REMOVE_CHOSEN_USERGROUP";
const REMOVE_USERGROUP_BY_COMPANY = "REMOVE_USERGROUP_BY_COMPANY";

// INITIAL STATE
const initialState = {
    userGroups: [],
    users: [],
    usersSearch: [],
    searchedUsergroups: [],
    groupsSearchText: "",
    chosenUserGroups: [],
};

// USER GROUPS REDUCER
export const userGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER_GROUP:
            return { ...state, userGroups: [...state.userGroups, action.userGroup] };
        case UPDATE_USER_GROUP:
            const newUserGroups = state.userGroups.map(userGroup => {
                if (userGroup.id === action.userGroup.id) {
                    return action.userGroup;
                }
                return userGroup;
            });
            return { ...state, userGroups: newUserGroups };
        case SET_USER_GROUPS:
            // // Combine current userGroups with new ones from the action
            // const combinedUserGroups = [...state.userGroups, ...action.userGroups];

            // // Use a Set to filter out duplicates based on a unique property (e.g., id)
            // const uniqueUserGroups = Array.from(
            //     new Set(combinedUserGroups.map(group => group.id))
            // ).map(id => combinedUserGroups.find(group => group.id === id));

            // return { ...state, userGroups: uniqueUserGroups };
            return { ...state, userGroups: action.userGroups};
        case SET_USERS:
            return { ...state, users: action.users };
        case SET_USERS_SEARCH:
            return { ...state, usersSearch: state.users.filter(user => user.name?.toLowerCase().includes(action.searchText?.toLowerCase())) };
        case CLEAR_USERS_SEARCH:
            return { ...state, usersSearch: [] };
        case DELETE_USER_FROM_USERGROUP:
            return {
                ...state,
                users: state?.users?.filter(user => user.id !== action.userId),
                usersSearch: state?.usersSearch?.filter(user => user.id !== action.userId),
            };
        case SET_SEARCHED_USERGROUPS:
            return {
                ...state,
                searchedUsergroups: state.userGroups.filter(group => group.name.toLowerCase().includes(state.groupsSearchText.toLowerCase())),
            };
        case SET_GROUPS_SEARCH_TEXT:
            return { ...state, groupsSearchText: action.text };
        case SET_CHOSEN_USERGROUPS:
            return {
                ...state,
                chosenUserGroups: [...state.chosenUserGroups,
                ...state.chosenUserGroups.some(group => group.id === action.usergroup.id) ? [] : [action.usergroup]
                ],
            };
        case REMOVE_CHOSEN_USERGROUP:
            return {
                ...state,
                chosenUserGroups: state.chosenUserGroups.filter(group => group.id !== action.groupId),
            };
        case REMOVE_USERGROUP_BY_COMPANY:
            return {
                ...state,
                userGroups: state.userGroups.filter(group => group.company !== action.companyId),
            };
        default:
            return state;
    }
};

// ACTION CREATORS
export const createUserGroupActionCreator = (userGroup) => ({ type: CREATE_USER_GROUP, userGroup });
export const updateUserGroupActionCreator = (userGroup) => ({ type: UPDATE_USER_GROUP, userGroup });
export const setUserGroupsActionCreator = (userGroups) => ({ type: SET_USER_GROUPS, userGroups });
export const setUsersActionCreator = (users) => ({ type: SET_USERS, users });
export const setUsersSearchActionCreator = (searchText) => ({ type: SET_USERS_SEARCH, searchText });
export const deleteUserFromUsergroupActionCreator = (userId) => ({ type: DELETE_USER_FROM_USERGROUP, userId });
export const clearUsersSearchActionCreator = () => ({ type: CLEAR_USERS_SEARCH });
export const setSearchedUsergroupsActionCreator = () => ({ type: SET_SEARCHED_USERGROUPS });
export const setGroupsSearchTextActionCreator = (text) => ({ type: SET_GROUPS_SEARCH_TEXT, text });
export const setChosenUsergroupsActionCreator = (usergroup) => ({ type: SET_CHOSEN_USERGROUPS, usergroup });
export const removeChosenUsergroupActionCreator = (groupId) => ({ type: REMOVE_CHOSEN_USERGROUP, groupId });
export const removeUsergroupByCompanyActionCreator = (companyId) => ({ type: REMOVE_USERGROUP_BY_COMPANY, companyId });

// THUNKS
export const createUserGroup = (name, description, company) => dispatch => {
    userGroupsAPI.createUserGroup(name, description, company)
        .then(response => {
            if (response.status === 201) {
                successToast(`Отдел ${name} успешно создан`);
                dispatch(createUserGroupActionCreator(response.data));
            }
        })
};
export const updateUserGroup = (name, description, accessed_tests, id) => dispatch => {
    userGroupsAPI.updateUserGroup(name, description, accessed_tests, id)
        .then(response => {
            dispatch(updateUserGroupActionCreator(response.data));
        })
};
export const getUserGroups = () => dispatch => {
    userGroupsAPI.getUserGroups()
        .then(response => {
            dispatch(setUserGroupsActionCreator(response.data));
        })
};
export const getUserGroupsOfCompany = (companyId) => dispatch => {
    userGroupsAPI.getUserGroupsByCompany(companyId)
        .then(response => {
            dispatch(stopLoadingActionCreator());
            dispatch(stopComponentLoadingActionCreator());
            dispatch(setUserGroupsActionCreator(response.data));
        })
};
export const getUsersOfGroup = (userGroupId) => dispatch => {
    userGroupsAPI.getUsersByGroup(userGroupId)
        .then(response => {
            dispatch(setUsersActionCreator(response.data));
            dispatch(stopComponentLoadingActionCreator());
        })
};