import Input from "../../components/UI/Input/Input";
import style from "./QuestionBlock.module.css";
import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";
import Checkbox from "../../components/functional/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";

// BLOCK FOR EACH SINGLE QUESTION IN THE TEST
const QuestionBlock = ({
  questionNumber,
  question,
  options,
  index,
  isEditMode,
  onQuestionChange,
  onQuestionDelete,
  onOptionChange,
  numberOfQuestions,
  isUpdate,
  onCorrectCheckboxChecked,
  onOptionDelete,
  onAddOption,
}) => {
  const { t } = useTranslation();

  // LAYOUT
  return (
    <div
      className={`flex__column ${style.question} ${
        !isEditMode ? style.questionView : ""
      }`}
    >
      {!isEditMode && (
        <div>
          <div>
            <p className={style.questionHeading}>Вопрос №{questionNumber}</p>
            <p className={style.questionText}>{question}</p>
          </div>
          <div className={`flex__column ${style.questionAnswersView}`}>
            {options?.length
              ? options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        option?.is_correct
                          ? style.questionAnswersSingleActive
                          : style.questionAnswersSingle
                      }
                    >
                      <p className={style.questionHeading}>
                        {option?.is_correct ? t("correctAnswer") : t("option")}
                      </p>
                      <p className={style.questionText}>{option.text}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )}
      {isEditMode && !isUpdate && (
        <div>
          <div className={style.questionBlock}>
            <p className={style.questionLabel}>Вопрос №{questionNumber}</p>
            <div className={`flex ${style.questionInput}`}>
              <Input
                type="text"
                id="question"
                onChange={onQuestionChange(index)}
                placeholder={t("question")}
                value={question?.text}
              />
              {index === 0 && numberOfQuestions === 1 ? null : (
                <ButtonNew
                  className="button_error_small"
                  icon="icon-trash"
                  onClick={() => onQuestionDelete(index)}
                />
              )}
            </div>
          </div>
          <div className={`flex ${style.questionAnswers}`}>
            {question?.choices.map((choice, i) => {
              return (
                <div className={style.questionAnswersSingle} key={i}>
                  <div className={`flex ${style.questionAnswersSingleHeading}`}>
                    <p className={style.questionLabel}>{t("option")}</p>
                    <div className="flex">
                      <div
                        className={`flex ${style.questionAnswersSingleHeadingCheckbox}`}
                      >
                        <Checkbox
                          onCheck={onCorrectCheckboxChecked(
                            index,
                            choice.index
                          )}
                          onUncheck={onCorrectCheckboxChecked(
                            index,
                            choice.index
                          )}
                          isChecked={choice.is_correct}
                          className="primary"
                        />
                        <p
                          className={
                            style.questionAnswersSingleHeadingCheckboxText
                          }
                        >
                          {t("correct")}
                        </p>
                      </div>
                      <i
                        className={`icon-trash ${style.questionAnswersSingleHeadingIcon}`}
                        onClick={onOptionDelete(index, choice.index)}
                      />
                    </div>
                  </div>
                  <div className={`flex ${style.questionAnswersSingleInput}`}>
                    <Input
                      type="text"
                      onChange={onOptionChange(index, choice.index)}
                      value={choice.text}
                      placeholder={t("answer")}
                      className="input-choice"
                    />
                  </div>
                </div>
              );
            })}
            <div className={style.questionAnswersAddButton}>
              <ButtonNew
                text={t("addAnswer")}
                className="button_transparent"
                icon="icon-add-square"
                onClick={onAddOption(index)}
              />
            </div>
          </div>
        </div>
      )}
      {isUpdate && (
        <div>
          <div className={style.questionBlock}>
            <p className={style.questionLabel}>
              {t("question")} №{questionNumber}
            </p>
            <div className={`flex ${style.questionInput}`}>
              <Input
                value={question}
                type="text"
                id="question"
                onChange={onQuestionChange(index)}
                placeholder={t("question")}
              />
              {index === 0 && numberOfQuestions === 1 ? null : (
                <ButtonNew
                  className="button_error_small"
                  icon="icon-trash"
                  onClick={() => onQuestionDelete(index)}
                />
              )}
            </div>
          </div>
          <div className={`flex ${style.questionAnswers}`}>
            {options.map((option, i) => {
              return (
                <div className={style.questionAnswersSingle} key={i}>
                  <div className={`flex ${style.questionAnswersSingleHeading}`}>
                    <p className={style.questionLabel}>{t("option")}</p>
                    <div className="flex">
                      <div
                        className={`flex ${style.questionAnswersSingleHeadingCheckbox}`}
                      >
                        <Checkbox
                          onCheck={onCorrectCheckboxChecked(
                            index,
                            option.index
                          )}
                          onUncheck={onCorrectCheckboxChecked(
                            index,
                            option.index
                          )}
                          isChecked={option.is_correct}
                          className="primary"
                        />
                        <p
                          className={
                            style.questionAnswersSingleHeadingCheckboxText
                          }
                        >
                          {t("correct")}
                        </p>
                      </div>
                      <i
                        className={`icon-trash ${style.questionAnswersSingleHeadingIcon}`}
                        onClick={onOptionDelete(index, option.index)}
                      />
                    </div>
                  </div>
                  <div className={`flex ${style.questionAnswersSingleInput}`}>
                    <Input
                      value={option.text}
                      type="text"
                      onChange={onOptionChange(index, option.index)}
                      placeholder={t("answer")}
                    />
                  </div>
                </div>
              );
            })}
            <div className={style.questionAnswersAddButton}>
              <ButtonNew
                text={t("addAnswer")}
                className="button_transparent"
                icon="icon-add-square"
                onClick={onAddOption(index)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// EXPORT
export default QuestionBlock;
