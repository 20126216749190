import { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./CompaniesEdit.module.css";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import {
  blockCompany,
  deleteCompany,
  getCompany,
  unblockCompany,
  updateCompany,
} from "../../../redux/companiesReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import { useLocation, useNavigate } from "react-router";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import FileDrop from "../../../components/functional/FileDrop/FileDrop";
import { setImage } from "../../../utils/imageUpload/setImage";
import { useTranslation } from "react-i18next";

// COMPANY EDIT PAGE
const CompaniesEdit = () => {
  const { t } = useTranslation();

  // USE STATE
  const [company, setCompany] = useState({});

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { state } = useLocation();

  // USE SELECTOR
  const { isLoading } = useSelector((state) => state.preloader);
  const { currentCompany } = useSelector((state) => state.companies);
  const { role } = useSelector((state) => state.auth);

  // IS ROLE PLATFORM ADMIN
  const isSuperUser = role === "super";

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE EFFECT
  useEffect(() => {
    if (state?.company?.id) {
      dispatch(startLoadingActionCreator());
      dispatch(getCompany(state.company.id));
    }
  }, [state?.test?.id]);
  useEffect(() => {
    if (currentCompany?.id) {
      setCompany({
        logo: currentCompany?.logo,
      });
    }
  }, [currentCompany?.id]);

  const links = [
    ...(isSuperUser
      ? [
          {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: t("companies"),
          },
        ]
      : []),
    {
      path: `/companies/${state?.company?.id}`,
      image: currentCompany?.logo,
      current: false,
      text: currentCompany?.name,
      state: { id: state?.company?.id },
    },
    {
      path: `/companies/edit/${state?.company?.id}`,
      icon: "icon-edit-2",
      current: true,
      text: t("editing"),
      state: {
        company: {
          id: state?.company?.id,
        },
      },
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: currentCompany?.logo,
      text: t("company"),
      current: true,
      path: `/companies/${state?.company?.id}`,
      state: {
        company: {
          id: state?.company?.id,
        },
      },
    },
    {
      icon: "icon-people",
      text: t("employees"),
      current: false,
      path: `/companies/${state?.company?.id}/staff`,
      state: { company: currentCompany },
    },
    {
      icon: "icon-money-tick",
      text: t("payments"),
      current: false,
      path: "/payments",
    },
    ...(!isSuperUser
      ? [
          {
            icon: "icon-chart",
            text: t("tariffs"),
            current: false,
            path: "/tariffs",
          },
        ]
      : []),
  ];

  // BUTTONS FOR NAVIGATION BAR
  const buttonsNavigation = [
    currentCompany?.active
      ? {
          text: t("block"),
          class: "button_error_outline",
          onClick: () => {
            // LOGIC TO GET DATE A YEAR LATER FROM NOW
            const currentDate = new Date();
            const oneYearLater = new Date(currentDate);
            oneYearLater.setFullYear(currentDate.getFullYear() + 1);

            const formattedDate = oneYearLater.toISOString();
            dispatch(blockCompany(formattedDate, state?.company?.id));
          },
        }
      : {
          text: t("unblock"),
          class: "button_gradient",
          onClick: () => {
            const currentDate = new Date();
            const oneYearLater = new Date(currentDate);
            oneYearLater.setFullYear(currentDate.getFullYear() + 1);

            const formattedDate = oneYearLater.toISOString();
            dispatch(unblockCompany(formattedDate, state?.company?.id));
          },
        },
    {
      text: t("delete"),
      class: "button_error",
      onClick: () => {
        dispatch(deleteCompany(state?.company?.id)).then((response) => {
          navigate("/companies");
        });
      },
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("back"),
      className: "button_transparent",
      onClick: () => {
        navigate(`/companies/${state?.company?.id}`, {
          state: { id: state?.company?.id },
        });
      },
    },
    {
      text: t("save"),
      className: "button_gradient",
      onClick: () => {
        const formData = new FormData();
        if (company.logo !== currentCompany.logo) {
          formData.append("logo", company.logo);
        }
        dispatch(updateCompany(formData, state?.company?.id));
      },
    },
  ];

  // HANDLING LOGO
  const handleLogo = (file) => {
    setCompany({
      ...company,
      logo: file,
    });
    setImage(file, "imagePreview");
    setImage(file, "crumbsImage");
    setImage(file, "crudImage");
  };

  // ON LOGO CHANGE
  const onLogoChange = (event) => {
    const file = event?.target?.files[0];
    handleLogo(file);
  };

  // ON DROP FILES EVENT
  const onDropFiles = (files) => {
    const file = files[0];
    handleLogo(file);
  };

  // ELEMENTS FOR EDITING TEST
  const elements = [
    {
      label: t("logo"),
      isFullWidth: true,
      item: (
        <FileDrop
          onChange={onLogoChange}
          onDropFiles={onDropFiles}
          image={company?.logo}
        />
      ),
    },
  ];

  // RETURN PRELOADER IF LOADING
  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.edit}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.editMain}`}>
        <NavigationBar
          links={linksNavigation}
          buttons={isSuperUser ? buttonsNavigation : []}
        />
        <div className={`flex__column ${style.editMainRight}`}>
          <CRUDHeader
            image={company?.logo}
            name={currentCompany?.name}
            category={currentCompany?.active ? t("active") : t("inactive")}
            buttons={buttonsHeader}
          />
          <InfoBlock
            heading={t("companyData")}
            isEditMode={true}
            values={elements}
          />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default CompaniesEdit;
