import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./Departments.module.css";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany } from "../../../redux/companiesReducer";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { useEffect, useState } from "react";
import Input from "../../../components/UI/Input/Input";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import {
  createUserGroup,
  getUserGroupsOfCompany,
  setUserGroupsActionCreator,
} from "../../../redux/userGroupsReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import TableComponent from "../../../components/functional/Table/Table";
import { errorToast } from "../../../utils/toasts/toasts";
import { useTranslation } from "react-i18next";

// DEPARTMENTS PAGE
const Departments = () => {
  const { t } = useTranslation();

  // USE STATE
  const [rows, setRows] = useState([]);
  const [departments, setDepartments] = useState([
    {
      name: "",
      index: 0,
    },
  ]);

  // CALLBACKS
  const onDepartmentChange = (index) => (event) => {
    setDepartments(
      departments.map((department) => {
        if (department.index === index) {
          return { ...department, name: event.target.value };
        }
        return department;
      })
    );
  };
  const deleteDepartment = (index) => () => {
    setDepartments(
      departments
        .filter((department) => department.index !== index)
        .map((department, index) => ({ ...department, index }))
    );
  };
  const addDepartment = () => {
    setDepartments([
      ...departments,
      {
        name: "",
        index: departments.length,
      },
    ]);
  };

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { state } = useLocation();
  const location = useLocation();

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE SELECTOR
  const { role } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.preloader);
  const { userGroups } = useSelector((state) => state.userGroups);

  const isSuperUser = role === "super";

  // USE EFFECT
  useEffect(() => {
    dispatch(startLoadingActionCreator());
    dispatch(getUserGroupsOfCompany(state?.company?.id));
  }, [state?.company?.id]);
  useEffect(() => {
    if (userGroups?.length) {
      const userGroupsRows = userGroups.map((userGroup) => ({
        id: userGroup.id,
        name: userGroup.name,
        numberOfEmployees: "-",
        created_at: "-",
        icon: "icon-dots",
      }));
      setRows(userGroupsRows);
    }
  }, [userGroups?.length]);

  // IF USER NAVIGATES AWAY FROM PAGE WE RESET STATE
  useEffect(() => {
    return () => {
      dispatch(setUserGroupsActionCreator([]));
    };
  }, [location]);

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    ...(isSuperUser
      ? [
          {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: t("companies"),
          },
        ]
      : []),
    {
      path: `/companies/${state?.company?.id}`,
      image: state?.company?.logo,
      current: false,
      text: state?.company?.name,
      state: { id: state?.company?.id },
    },
    {
      path: `/companies/${state?.company?.id}/staff`,
      icon: "icon-people",
      current: false,
      text: t("employees"),
      state: { company: state?.company },
    },
    {
      path: `/companies/${state?.company?.id}/departments`,
      icon: "icon-add-square",
      current: true,
      text: t("departmentCreation"),
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: state?.company?.logo,
      text: t("company"),
      current: false,
      path: `/companies/${state?.company?.id}`,
      state: { id: state?.company?.id },
    },
    {
      icon: "icon-people",
      text: t("employees"),
      current: true,
      path: `/companies/${state?.company?.id}/staff`,
      state: { company: state?.company },
    },
    {
      icon: "icon-money-tick",
      text: t("payments"),
      current: false,
      path: "/payments",
    },
    ...(!isSuperUser
      ? [
          {
            icon: "icon-chart",
            text: t("tariffs"),
            current: false,
            path: "/tariffs",
          },
        ]
      : []),
  ];

  // BUTTONS FOR NAVIGATION BAR
  const buttons = [
    {
      text: t("delete"),
      class: "button_error",
      onClick: () => {
        dispatch(deleteCompany(state?.company?.id)).then((response) => {
          navigate("/companies");
        });
      },
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("back"),
      className: "button_transparent",
      onClick: () => {
        navigate(`/companies/${state?.company?.id}/staff`, {
          state: { company: state?.company },
        });
      },
    },
    {
      text: t("save"),
      className: "button_gradient",
      onClick: () => {
        if (departments.some((department) => department.name === "")) {
          errorToast(t("someDataIsMissing"));
          return;
        }
        setDepartments([
          {
            name: "",
            index: 0,
          },
        ]);
        departments.forEach((department) => {
          dispatch(
            createUserGroup(
              department.name,
              department.name,
              state?.company?.id
            )
          );
        });
      },
    },
  ];

  // COLUMNS OF A TABLE
  const columns = [
    t("department"),
    t("employeesNumber"),
    t("dateCreated"),
    "icon-dots",
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.departments}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.departmentsMain}`}>
        <NavigationBar
          links={linksNavigation}
          buttons={isSuperUser ? buttons : []}
        />
        <div className={`flex__column ${style.viewMainData}`}>
          <CRUDHeader
            image={state?.company?.logo}
            name={state?.company?.name}
            buttons={buttonsHeader}
          />
          <div className={`flex__column ${style.viewMainDataRows}`}>
            <h3 className={style.viewMainDataRowsHeading}>
              {t("departmentCreation")}
            </h3>
            <div
              className={`flex__column ${style.viewMainDataRowsDepartments}`}
            >
              {departments.map((department, index) => {
                return (
                  <div
                    className={`flex__column 
                                            ${
                                              style.viewMainDataRowsDepartmentsSingle
                                            } 
                                            ${
                                              index === departments.length - 1
                                                ? style.viewMainDataRowsDepartmentsSingleLast
                                                : ""
                                            }`}
                    key={index}
                  >
                    <p className={style.viewMainDataRowsDepartmentsSingleText}>
                      {t("departmentName")}
                    </p>
                    <div
                      className={`flex ${style.viewMainDataRowsDepartmentsSingleInput}`}
                    >
                      <Input
                        type="text"
                        id={department.index}
                        onChange={onDepartmentChange(department.index)}
                        placeholder={t("marketing")}
                        value={department.name}
                      />
                      {index === 0 && departments.length === 1 ? null : (
                        <ButtonNew
                          className="button_error_small"
                          icon="icon-trash"
                          onClick={deleteDepartment(department.index)}
                        />
                      )}
                      {index === departments.length - 1 && (
                        <ButtonNew
                          className="button_gradient_small"
                          icon="icon-add-square"
                          onClick={addDepartment}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <TableComponent
            columns={columns}
            rows={rows}
            entity="departments"
            headerText={t("departments")}
          />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default Departments;
