import style from "./SearchInput.module.css";
import noImage from "../../../assets/images/no-image.png";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startComponentLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../Preloader/Preloader";
import { useTranslation } from "react-i18next";

// SEARCH COMPONENT
const SearchInput = ({
  isDropdown,
  headingText,
  onFocusEvent,
  items,
  searchedItems,
  onSearchInputChange,
  searchText,
  onItemClick,
  id,
}) => {
  const { t } = useTranslation();

  // USE SELECTOR
  const { isComponentLoading } = useSelector((state) => state.preloader);

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE STATE
  const [isFocused, setIsFocused] = useState(false);

  // LOGIC FOR A CUSTOM SCROLLBAR
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbTop, setThumbTop] = useState(0);

  useEffect(() => {
    const contentElem = document.querySelector(`.${style.dropdownList}`);
    if (contentElem) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = contentElem;

        const thumbHeightRatio = clientHeight / scrollHeight;
        const maxThumbTop = clientHeight - thumbHeight * thumbHeightRatio;

        setThumbHeight(thumbHeightRatio * clientHeight);
        setThumbTop((scrollTop / (scrollHeight - clientHeight)) * maxThumbTop);
      };
      contentElem.addEventListener("scroll", handleScroll);

      return () => contentElem.removeEventListener("scroll", handleScroll);
    }
  }, [isDropdown, isFocused]);
  useEffect(() => {
    if (isFocused) {
      dispatch(startComponentLoadingActionCreator());
      onFocusEvent();
    }
  }, [isFocused]);

  // FUNCTIONS TO HANDLE INPUT'S FOCUS STATE
  const handleFocus = function () {
    if (isDropdown) {
      setIsFocused(true);
    }
  };
  const handleBlur = function (event) {
    const clickedElementRelated = event.relatedTarget;
    if (clickedElementRelated) {
      return;
    }
    setIsFocused(false);
  };

  // HANDLING THE CHOICE OF SOME ELEMENT
  const onElementClick = function (item) {
    onItemClick(item)();
    setIsFocused(false);
  };

  // INPUT CHANGE HANDLER
  const onInputChange = function (event) {
    // FOR FUTURE USE
  };

  // CHECKING THE OUTSIDE CLICKS
  document.addEventListener("click", (event) => {
    if (event.target.closest(`#items-${id}`) && isDropdown) {
      return;
    }
    if (
      (event.target.closest(`#dropdown-${id}`) ||
        event.target.closest(`#search-${id}`)) &&
      isDropdown
    ) {
      setIsFocused(true);
      return;
    } else {
      setIsFocused(false);
    }
  });

  // LAYOUT
  return (
    <div className={`flex__column ${style.searchDropdown}`}>
      <div
        className={`${style.search} ${
          isDropdown && isFocused ? style.searchFocused : ""
        }`}
      >
        <i className={`icon-search ${style.searchIcon}`} />
        <input
          className={style.searchInput}
          placeholder={t("search")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={isDropdown ? onSearchInputChange : onInputChange}
          id={`search-${id}`}
        />
      </div>
      {isDropdown &&
        isFocused &&
        (isComponentLoading ? (
          <div className="preloader preloader_search">
            <Preloader />
          </div>
        ) : (
          <div
            className={`flex__column ${style.dropdown}`}
            tabIndex="0"
            id={`dropdown-${id}`}
          >
            <p className={style.dropdownHeading}>
              {headingText} (
              {searchedItems?.length || searchText
                ? searchedItems?.length
                : items?.length}
              )
            </p>
            <div
              className={`flex__column ${style.dropdownList}`}
              id={`items-${id}`}
            >
              {searchedItems?.length || searchText
                ? searchedItems.map((item, index) => {
                    return (
                      <div
                        className={`flex ${style.dropdownListItem}`}
                        onClick={() => onElementClick(item)}
                        key={index}
                      >
                        <img
                          src={item?.logo || noImage}
                          alt="logo"
                          className={style.dropdownListItemImage}
                        />
                        <p className={style.dropdownListItemText}>
                          {item.name}
                        </p>
                      </div>
                    );
                  })
                : items.map((item, index) => {
                    return (
                      <div
                        className={`flex ${style.dropdownListItem}`}
                        onClick={() => onElementClick(item)}
                        key={index}
                      >
                        <img
                          src={item?.logo || noImage}
                          alt="logo"
                          className={style.dropdownListItemImage}
                        />
                        <p className={style.dropdownListItemText}>
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
            </div>
            <div
              className={style.thumb}
              style={{ height: `${thumbHeight}px`, top: `${thumbTop}px` }}
            ></div>
          </div>
        ))}
    </div>
  );
};

// EXPORT
export default SearchInput;
