import { useCallback, useEffect, useState } from "react";
import style from "./FileDrop.module.css";
import { useTranslation } from "react-i18next";

// FILE DRAG N DROP COMPONENT
const FileDrop = ({ onChange, onDropFiles, image }) => {
  const { t } = useTranslation();

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isImage, setIsImage] = useState(image ? true : false);

  // USE EFFECT
  useEffect(() => {
    if (image) {
      setIsImage(true);
    }
  }, [image]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDraggingOver(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    // Access the files from the event
    const files = event.dataTransfer.files;
    onDropFiles(files);
  }, []);

  // LAYOUT
  return (
    <div className={`flex ${style.file}`}>
      <div
        className={`flex__column ${style.fileDragndrop}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <img
          src={image}
          className={`${style.fileImage} ${!isImage && style.imageHidden}`}
          id="imagePreview"
        />
        <i className={`icon-document-upload ${style.fileDragndropIcon}`} />
        <label htmlFor="file" className={style.fileDragndropLabel}>
          <input
            type="file"
            className={style.fileHidden}
            onChange={onChange}
            id="file"
          />
          <p className={style.fileDragndropText}>
            {t("dragOrUpload1")}{" "}
            <span className={style.fileDragndropTextHighlighted}>
              {t("dragOrUpload2")}
            </span>{" "}
            {t("dragOrUpload3")}
          </p>
        </label>
      </div>
    </div>
  );
};

// EXPORT
export default FileDrop;
