import style from "./ProjectsDropdown.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const ProjectsDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onDropdownToggle = function () {
    setIsDropdownOpen((previous) => !previous);
  };
  const goToRecruitment = function () {
    window.location.href = "https://nohunt.uz/";
  };
  const goToTesting = function () {
    navigate("/");
  };

  const closeDropdown = () => {
    setTimeout(() => setIsDropdownOpen(false), 100);
  };

  // LAYOUT
  return (
    <div className={style.projectsDropdown}>
      <div
        className={`flex ${style.projectsDropdownIcon} ${
          isDropdownOpen ? style.projectsDropdownIconActive : ""
        }`}
        onClick={onDropdownToggle}
        onBlur={closeDropdown}
        tabIndex={1}
      >
        <div className={`flex__column ${style.projectsDropdownIconBlock}`}>
          <div
            className={`${style.projectsDropdownIconLeftSmallBlock} ${style.projectsDropdownIconLeftSmallBlockActive}`}
          ></div>
          <div className={style.projectsDropdownIconLeftLargeBlock}></div>
        </div>
        <div className={`flex__column ${style.projectsDropdownIconBlock}`}>
          <div className={style.projectsDropdownIconLeftLargeBlock}></div>
          <div className={style.projectsDropdownIconLeftSmallBlock}></div>
        </div>
      </div>
      {isDropdownOpen && (
        <div className={`flex__column ${style.projectsDropdownMenu}`}>
          <h3 className={style.projectsDropdownMenuHeading}>
            {t("allProjects")}
          </h3>
          <div
            className={`flex ${style.projectsDropdownMenuItem}`}
            onClick={goToRecruitment}
          >
            <i
              className={`icon-user-search ${style.projectsDropdownMenuItemIcon}`}
            />
            <div
              className={`flex__column ${style.projectsDropdownMenuItemDescription}`}
            >
              <p className={style.projectsDropdownMenuItemDescriptionText}>
                {t("recruiting")}
              </p>
              <p className={style.projectsDropdownMenuItemDescriptionSmallText}>
                {t("searchAndEmployment")}
              </p>
            </div>
          </div>
          <div
            className={`flex ${style.projectsDropdownMenuItemActive} ${style.projectsDropdownMenuItem}`}
            onClick={goToTesting}
          >
            <i
              className={`icon-document-normal ${style.projectsDropdownMenuItemIcon}`}
            />
            <div
              className={`flex__column ${style.projectsDropdownMenuItemDescription}`}
            >
              <p className={style.projectsDropdownMenuItemDescriptionText}>
                {t("testing")}
              </p>
              <p className={style.projectsDropdownMenuItemDescriptionSmallText}>
                {t("testsCreation")}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// EXPORT
export default ProjectsDropdown;
