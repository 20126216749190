import AuthorizationHeader from "./AuthorizationHeader/AuthorizationHeader";
import style from "./AuthorizationWrapper.module.css";

const AuthorizationWrapper = ({ children, footerTexts }) => {
  return (
    <div className={`${style.auth}`}>
      <div className={`flex__column ${style.authPage}`}>
        <AuthorizationHeader />
        <div className={`flex__column ${style.authWrapper}`}>{children}</div>
        {footerTexts}
      </div>
    </div>
  );
};

export default AuthorizationWrapper;
