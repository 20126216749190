import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./StaffEdit.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "../../../redux/usersReducer";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import avatar from "../../../assets/images/avatar.png";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import Input from "../../../components/UI/Input/Input";
import { convertDateFromHyphen } from "../../../utils/dateConverter/convertDateFromHyphen";
import ChoiceTab from "../../../components/functional/ChoiceTab/ChoiceTab";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import { passwordGenerate } from "../../../utils/passwordGenerator/passwordGenerator";
import { errorToast } from "../../../utils/toasts/toasts";
import { convertDateToISO } from "../../../utils/dateConverter/convertDateToISO";
import { convertDateToHyphen } from "../../../utils/dateConverter/convertDateToHyphen";
import { logout } from "../../../redux/authReducer";
import { useTranslation } from "react-i18next";

// PAGE FOR EDITING STAFF MEMBER
const StaffEdit = () => {
  const { t } = useTranslation();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE STATE
  // LOCAL STATE FOR STAFF MEMBER
  const [staffMember, setStaffMember] = useState({});

  // USE SELECTOR
  const { isLoading } = useSelector((state) => state.preloader);
  const { currentUser } = useSelector((state) => state.users);
  const { role, profileData } = useSelector((state) => state.auth);

  const isSuperUser = role === "super";
  const isUser = role === "user";

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE LOCATION
  const { state } = useLocation();

  // USE EFFECT
  useEffect(() => {
    if (state?.staff?.id) {
      dispatch(startLoadingActionCreator());
      dispatch(getUser(state?.staff?.id));
    }
  }, [state?.staff?.id]);
  useEffect(() => {
    // CHECKING IF USER CAME FROM SERVER
    if (currentUser?.id) {
      // SETTING LOCAL STAFF MEMBER WITH DATA FROM SERVER
      setStaffMember({
        name: currentUser.name,
        date_of_birth: currentUser.date_of_birth,
        gender: currentUser.gender,
        email: currentUser.email,
        phone: currentUser.phone,
        password: "",
        confirmedPassword: "",
      });
    }
  }, [currentUser?.id]);

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    ...(isSuperUser
      ? [
          {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: t("companies"),
          },
        ]
      : []),
    {
      path: `/companies/${state?.company?.id}`,
      image: state?.company?.logo,
      current: false,
      text: state?.company?.name,
      state: { id: state?.company?.id },
    },
    {
      path: `/companies/${state?.company?.id}/staff`,
      icon: "icon-people",
      current: false,
      text: t("employees"),
      state: { company: state?.company },
    },
    {
      path: `/companies/${state?.company?.id}/staff/${state?.staff?.id}`,
      current: false,
      text: currentUser?.name,
      state: { id: state?.staff?.id, state: { company: state?.company } },
    },
    {
      path: `/companies/${state?.company?.id}/staff/${state?.staff?.id}/edit`,
      icon: "icon-edit-2",
      current: true,
      text: t("editing"),
      state: { staff: { id: state?.staff?.id }, company: state?.company },
    },
  ];
  // LINKS FOR STAFF MEMBERS
  const linksUser = [
    {
      path: "/",
      current: false,
      icon: "icon-profile-circle",
      text: t("profile"),
      state: { id: state?.id || profileData?.id },
    },
    {
      path: "/edit",
      current: true,
      icon: "icon-edit-2",
      text: t("editing"),
      state: { staff: { id: state?.staff?.id || profileData?.id } },
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: state?.company?.logo,
      text: t("company"),
      current: false,
      path: `/companies/${state?.company?.id}`,
      state: { id: state?.company?.id },
    },
    {
      icon: "icon-people",
      text: t("employees"),
      current: true,
      path: `/companies/${state?.company?.id}/staff`,
      state: { company: state?.company },
    },
    {
      icon: "icon-money-tick",
      text: t("payments"),
      current: false,
      path: "/payments",
    },
    ...(!isSuperUser
      ? [
          {
            icon: "icon-chart",
            text: t("tariffs"),
            current: false,
            path: "/tariffs",
          },
        ]
      : []),
  ];
  // LINKS AT NAVBAR FOR USER ROLE
  const linksNavigationUser = [
    {
      icon: "icon-profile-circle",
      text: t("profile"),
      current: true,
      path: "/",
    },
  ];

  // BUTTONS ON NAVBAR FOR USER ROLE
  const buttonsNavigationUser = [
    {
      text: t("logout"),
      class: "button_error_outline",
      onClick: () => {
        dispatch(logout());
        navigate("/login");
      },
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("back"),
      className: "button_transparent",
      onClick: () => {
        if (isUser) {
          navigate("/");
        } else {
          navigate(
            `/companies/${state?.company?.id}/staff/${state?.staff?.id}`,
            {
              state: {
                id: state?.staff?.id,
                state: { company: state?.company },
              },
            }
          );
        }
      },
    },
    {
      text: t("save"),
      className: "button_gradient",
      onClick: () => {
        // CHECKING IF THERE ARE ANY EMPTY FIELDS LEFT
        for (let [key, value] of Object.entries(staffMember)) {
          // IF IT IS NOT PASSWORD THEN WE CHECK IF THE FIELD IS EMPTY OR NOT
          if (key !== "password" && key !== "confirmedPassword") {
            if (!value) {
              errorToast(t("someDataIsMissing"));
              return;
            }
          }
        }

        // CREATING A FORM DATA WHICH WILL STORE DATA
        const formData = new FormData();

        // CHECKING IF NEW PASSWORD WAS SET
        if (staffMember?.password || staffMember?.confirmedPassword) {
          // CHECKING IF THEY MATCH
          if (staffMember?.password !== staffMember?.confirmedPassword) {
            // THROWING ERROR IF NO MATCH
            errorToast(t("passwordsDoNotMatch"));
            return;
          } else {
            // APPENDING PASSWORD TO FORM DATA
            formData.append("password", staffMember?.password);
          }
        }

        // APPENDING ALL OTHER FIELDS TO FORM DATA
        formData.append("name", staffMember?.name);
        formData.append("gender", staffMember?.gender);
        formData.append(
          "date_of_birth",
          staffMember?.date_of_birth.includes("-")
            ? staffMember?.date_of_birth
            : convertDateToHyphen(staffMember?.date_of_birth)
        );
        formData.append("email", staffMember?.email);
        formData.append(
          "phone",
          staffMember?.phone.replace(/(?!^\+)[^\d]/g, "")
        );

        // DISPATCHING ACTION OF UPDATING A STAFF MEMBER
        dispatch(
          updateUser(formData, state?.staff?.id || profileData?.id)
        ).then((response) => {
          if (isUser) {
            navigate("/");
          } else {
            navigate(
              `/companies/${state?.company?.id}/staff/${state?.staff?.id}`,
              {
                state: {
                  id: state?.staff?.id,
                  state: { company: state?.company },
                },
              }
            );
          }
        });
      },
    },
  ];

  // ELEMENTS FOR EDITING STAFF MEMBER
  // FIRST BLOCK WITH PERSONAL DATA
  const personalDataElements = [
    {
      label: t("name"),
      item: (
        <Input
          value={staffMember?.name}
          type="text"
          id="staffMemberName"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              name: event.target.value,
            });
          }}
          placeholder="John Doe"
        />
      ),
    },
    {
      label: t("birthDate"),
      item: (
        <Input
          mask="00.00.0000"
          value={
            staffMember?.date_of_birth
              ? convertDateFromHyphen(staffMember?.date_of_birth)
              : ""
          }
          type="text"
          id="staffMemberBirth"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              date_of_birth: event.target.value,
            });
          }}
          placeholder="12.02.2002"
        />
      ),
    },
    {
      label: t("sex"),
      item: (
        <div className={`flex ${style.choiceTabs}`}>
          <ChoiceTab
            isActive={staffMember?.gender === "male"}
            text={t("male")}
            onClick={() => setStaffMember({ ...staffMember, gender: "male" })}
          />
          <ChoiceTab
            isActive={staffMember?.gender === "female"}
            text={t("female")}
            onClick={() => setStaffMember({ ...staffMember, gender: "female" })}
          />
        </div>
      ),
    },
    {
      label: t("email"),
      item: (
        <Input
          value={staffMember?.email}
          type="email"
          id="staffMemberEmail"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              email: event.target.value,
            });
          }}
          placeholder="aleksandr@nohunt.com"
        />
      ),
    },
    {
      label: t("phoneNumber"),
      item: (
        <Input
          mask="+998(00)000-00-00"
          value={staffMember?.phone || ""}
          type="text"
          id="staffMemberPhone"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              phone: event.target.value,
            });
          }}
          placeholder="+998(00)485-75-92"
        />
      ),
    },
  ];
  // SECURITY ELEMENTS
  // A FUNCTION GENERATING PASSWORD
  const generatePassword = function () {
    // GENERATING NEW PASSWORD USING passwordGenerate FUNCTION
    const password = passwordGenerate();

    // SETTING STAFF MEMBER's PASSWORD TO THAT VALUE
    setStaffMember({
      ...staffMember,
      password,
    });
  };
  const securityElements = [
    {
      label: t("newPassword"),
      item: (
        <div className={`flex ${style.passwordGenerate}`}>
          <Input
            value={staffMember?.password}
            type="text"
            id="staffMemberPassword"
            onChange={(event) => {
              setStaffMember({
                ...staffMember,
                password: event.target.value,
              });
            }}
            placeholder={t("newPassword")}
          />
          <ButtonNew
            className="button_gradient_small"
            icon="icon-box"
            onClick={generatePassword}
          />
        </div>
      ),
    },
    {
      label: t("confirmNewPassword"),
      item: (
        <Input
          value={staffMember?.confirmedPassword}
          type="text"
          id="staffMemberConfirmedPassword"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              confirmedPassword: event.target.value,
            });
          }}
          placeholder={t("confirmNewPassword")}
        />
      ),
    },
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.edit}`}>
      <BreadCrumbs links={isUser ? linksUser : links} />
      <div className={`flex ${style.editMain}`}>
        <NavigationBar
          links={isUser ? linksNavigationUser : linksNavigation}
          buttons={isUser ? buttonsNavigationUser : []}
        />
        <div className={`flex__column ${style.editMainData}`}>
          <CRUDHeader
            image={avatar}
            name={currentUser?.name}
            buttons={buttonsHeader}
          />
          <InfoBlock
            heading={t("personalData")}
            isEditMode={true}
            values={personalDataElements}
          />
          <InfoBlock
            heading={t("security")}
            isEditMode={true}
            values={securityElements}
          />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default StaffEdit;
