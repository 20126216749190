import { Field, Form } from "react-final-form";
import style from "./PasswordReset.module.css";
import { useState } from "react";
import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";
import { useTranslation } from "react-i18next";
import AuthorizationWrapper from "../AuthorizationWrapper/AuthorizationWrapper";
import { NavLink } from "react-router-dom";

// PASSWORD RESET FORM
const PasswordResetForm = ({ step }) => {
  const { t } = useTranslation();
  const onSubmit = function (values) {
    console.log("VALUES", values);
  };

  // FORM
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={`flex__column ${style.passwordResetForm}`}>
            {step === 1 && (
              <div className={`flex__column ${style.passwordResetFormInputs}`}>
                <div className="flex__column">
                  <label className="text__label">{t("email")}</label>
                  <Field
                    name="email"
                    render={({ input }) => (
                      <input
                        {...input}
                        type="email"
                        placeholder={t("enterEmail")}
                        className={`input ${style.passwordResetInput}`}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <ButtonNew
              type="submit"
              text={t("send")}
              className="button_gradient"
            />
          </div>
        </form>
      )}
    />
  );
};

// PAGE FOR RESETTING PASSWORD
const PasswordReset = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(
    localStorage.getItem("passwordResetStep") ?? 1
  );
  const footerText = (
    <NavLink to={"/login"} className={style.passwordResetFooterLink}>
      {t("backToLogin")}
    </NavLink>
  );

  // LAYOUT
  return (
    <AuthorizationWrapper footerTexts={footerText}>
      <div className={`flex__column ${style.passwordReset}`}>
        <div className={style.passwordResetHeading}>
          <h2 className={style.passwordResetHeadingTitle}>
            {t("forgotPassword")}
          </h2>
          <p className={style.passwordResetHeadingText}>
            {t("weWillSendCodeToEmail")}
          </p>
        </div>
        <PasswordResetForm step={step} />
      </div>
    </AuthorizationWrapper>
  );
};

export default PasswordReset;
