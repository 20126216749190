import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./StaffCreate.module.css";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany } from "../../../redux/companiesReducer";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import Input from "../../../components/UI/Input/Input";
import { useEffect, useState } from "react";
import SelectComponent from "../../../components/functional/SelectComponent/SelectComponent";
import {
  getUserGroupsOfCompany,
  setUserGroupsActionCreator,
} from "../../../redux/userGroupsReducer";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import { errorToast } from "../../../utils/toasts/toasts";
import { createUser } from "../../../redux/usersReducer";
import { useTranslation } from "react-i18next";

// PAGE FOR CREATING STAFF MEMBERS
const StaffCreate = () => {
  const { t } = useTranslation();

  // USE LOCATION
  const { state } = useLocation();
  const location = useLocation();

  // USE STATE
  const [departmentsSelect, setDepartmentsSelect] = useState([]);
  const [staffMember, setStaffMember] = useState({
    name: "",
    departmentId: "",
    email: "",
    password: "",
    companyId: state?.company?.id,
  });

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE SELECTOR
  const { role } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.preloader);
  const { userGroups } = useSelector((state) => state.userGroups);

  const isSuperUser = role === "super";

  // USE EFFECT
  useEffect(() => {
    dispatch(startLoadingActionCreator());
    dispatch(getUserGroupsOfCompany(state?.company?.id));
  }, [state?.company?.id]);
  useEffect(() => {
    if (userGroups?.length) {
      setDepartmentsSelect(
        userGroups.map((userGroup) => ({
          label: userGroup.name,
          value: userGroup.id,
        }))
      );
    }
  }, [userGroups?.length]);
  useEffect(() => {
    return () => {
      dispatch(setUserGroupsActionCreator([]));
    };
  }, [location]);

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    ...(isSuperUser
      ? [
          {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: t("companies"),
          },
        ]
      : []),
    {
      path: `/companies/${state?.company?.id}`,
      image: state?.company?.logo,
      current: false,
      text: state?.company?.name,
      state: { id: state?.company?.id },
    },
    {
      path: `/companies/${state?.company?.id}/staff`,
      icon: "icon-people",
      current: false,
      text: t("employees"),
      state: { company: state?.company },
    },
    {
      path: `/companies/${state?.company?.id}/staff/create`,
      icon: "icon-add-square",
      current: true,
      text: t("employeeCreation"),
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: state?.company?.logo,
      text: t("company"),
      current: false,
      path: `/companies/${state?.company?.id}`,
      state: { id: state?.company?.id },
    },
    {
      icon: "icon-people",
      text: t("employees"),
      current: true,
      path: `/companies/${state?.company?.id}/staff`,
      state: { company: state?.company },
    },
    {
      icon: "icon-money-tick",
      text: t("payments"),
      current: false,
      path: "/payments",
    },
    ...(!isSuperUser
      ? [
          {
            icon: "icon-chart",
            text: t("tariffs"),
            current: false,
            path: "/tariffs",
          },
        ]
      : []),
  ];

  // BUTTONS FOR NAVIGATION BAR
  const buttons = [
    {
      text: t("delete"),
      class: "button_error",
      onClick: () => {
        dispatch(deleteCompany(state?.company?.id)).then((response) => {
          navigate("/companies");
        });
      },
    },
  ];

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("back"),
      className: "button_transparent",
      onClick: () => {
        navigate(`/companies/${state?.company?.id}/staff`, {
          state: { company: state?.company },
        });
      },
    },
    {
      text: t("save"),
      className: "button_gradient",
      onClick: () => {
        if (
          staffMember.name === "" ||
          staffMember.departmentId === "" ||
          staffMember.email === ""
        ) {
          errorToast(t("someDataIsMissing"));
          return;
        }
        const { name, departmentId, email, password, companyId } = staffMember;
        dispatch(
          createUser(
            name,
            [departmentId],
            email,
            password,
            companyId,
            navigate,
            state?.company
          )
        );
      },
    },
  ];

  // ELEMENTS FOR CREATING EMPLOYEE
  const elements = [
    {
      label: t("employeeName"),
      item: (
        <Input
          value={staffMember?.name}
          type="text"
          id="staffMemberName"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              name: event.target.value,
            });
          }}
          placeholder={t("name")}
        />
      ),
    },
    {
      label: t("departmentName"),
      item: (
        <SelectComponent
          value={departmentsSelect?.find(
            (department) => department?.value === staffMember?.departmentId
          )}
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              departmentId: event.value,
            });
          }}
          options={departmentsSelect}
        />
      ),
    },
    {
      label: t("email"),
      item: (
        <Input
          value={staffMember?.email}
          type="email"
          id="staffMemberEmail"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              email: event.target.value,
            });
          }}
          placeholder={t("email")}
        />
      ),
    },
    {
      label: t("password"),
      item: (
        <Input
          value={staffMember?.password}
          type="password"
          id="staffMemberPassword"
          onChange={(event) => {
            setStaffMember({
              ...staffMember,
              password: event.target.value,
            });
          }}
          placeholder={t("password")}
        />
      ),
    },
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.create}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.createMain}`}>
        <NavigationBar
          links={linksNavigation}
          buttons={isSuperUser ? buttons : []}
        />
        <div className={`flex__column ${style.createMainData}`}>
          <CRUDHeader
            image={state?.company?.logo}
            name={state?.company?.name}
            buttons={buttonsHeader}
          />
          <InfoBlock
            heading={t("employeeCreation")}
            isEditMode={true}
            values={elements}
          />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default StaffCreate;
