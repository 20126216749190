import ProjectsDropdown from "../../../components/UI/ProjectsDropdown/ProjectsDropdown";
import style from "./AuthorizationHeader.module.css";
import Logo from "../../../assets/images/logo_black.png";
import USAFlag from "../../../assets/svg/flag-for-us.svg";
import RusFlag from "../../../assets/svg/flag-for-russia.svg";
import UzFlag from "../../../assets/svg/flag-for-uzbekistan.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AuthorizationHeader = () => {
  const { t, i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("locale") || "en";
  const locales = ["en", "ru", "uz"];
  const flags = {
    en: USAFlag,
    ru: RusFlag,
    uz: UzFlag,
  };
  const changeLocale = function (e) {
    e.stopPropagation();

    const currentLocaleIndex = locales.findIndex(
      (locale) => locale === savedLanguage
    );
    let locale = locales[currentLocaleIndex + 1];

    if (currentLocaleIndex === 2) {
      locale = locales[0];
    }

    i18n.changeLanguage(locale);
    localStorage.setItem("locale", locale);
  };

  return (
    <div className={`flex__2 ${style.authHeader}`}>
      <div className={`flex ${style.authHeaderBranding}`}>
        <ProjectsDropdown />
        <NavLink to={"/"}>
          <img src={Logo} alt="logo" className={style.authHeaderBrandingLogo} />
        </NavLink>
      </div>
      <div
        className={`flex ${style.authHeaderLanguage}`}
        onClick={changeLocale}
      >
        <img
          src={flags[savedLanguage]}
          alt="flag"
          className={style.authHeaderLanguageFlag}
        />
        <p className={style.authHeaderLanguageText}>{t(savedLanguage)}</p>
      </div>
    </div>
  );
};

export default AuthorizationHeader;
