import { useEffect, useState } from "react";
import NavigationBar from "../../components/UI/NavigationBar/NavigationBar";
import BreadCrumbs from "../../components/functional/BreadCrumbs/BreadCrumbs";
import TableComponent from "../../components/functional/Table/Table";
import style from "./Tests.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getTests } from "../../redux/testsReducer";
import { formatDuration } from "../../utils/durationConverter/durationConverter";
import { startLoadingActionCreator } from "../../redux/preloaderReducer";
import Preloader from "../../components/UI/Preloader/Preloader";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

// TESTS PAGE
const Tests = () => {
  const { t } = useTranslation();

  // USE STATE
  const [rows, setRows] = useState([]);

  // USE SELECTOR
  const testsState = useSelector((state) => state.tests);
  const { isLoading } = useSelector((state) => state.preloader);

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE EFFECT
  useEffect(() => {
    dispatch(startLoadingActionCreator());
    dispatch(getTests());
  }, [dispatch]);
  useEffect(() => {
    if (testsState?.tests.length) {
      const testRows = testsState?.tests?.map((row) => {
        return {
          id: row.id,
          name: row.name,
          type: row.type,
          time: row.time_allocated ? formatDuration(row.time_allocated) : "Нет",
          questions: row?.questions?.length,
          icon: "icon-dots",
        };
      });
      setRows(testRows);
    }
  }, [testsState?.tests]);

  // LINKS ARRAY FOR BREADCRUMBS
  const links = [
    {
      path: "/tests",
      icon: "icon-document-normal",
      current: true,
      text: t("testingCenter"),
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      icon: "icon-document-text",
      text: t("assignment"),
      current: false,
      path: "/tests/assign",
    },
    {
      icon: "icon-document-normal",
      text: t("tests"),
      current: true,
      path: "/tests",
    },
    {
      icon: "icon-clipboard-tick",
      text: t("results"),
      current: false,
      path: "/results",
    },
  ];

  // COLUMNS OF A TABLE
  const columns = [
    t("naming"),
    t("category"),
    t("time"),
    t("questions"),
    "icon-dots",
  ];

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.tests}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.testsMain}`}>
        <NavigationBar links={linksNavigation} buttons={[]} />
        <TableComponent
          columns={columns}
          rows={rows}
          entity="tests"
          headerText={t("tests")}
          headerButtonText={t("addTest")}
        />
      </div>
    </div>
  );
};

// EXPORT
export default Tests;
