import { useNavigate } from "react-router";
import ButtonNew from "../../../UI/ButtonNew/ButtonNew";
import SearchInput from "../../../UI/SearchInput/SearchInput";
import style from "./TableHeader.module.css";
import { useTranslation } from "react-i18next";

// TABLE HEADER COMPONENT
const TableHeader = ({ text, buttonText, entity, page, state }) => {
  const { t } = useTranslation();

  // USE NAVIGATE
  const navigate = useNavigate();

  // CALLBACK FOR ADD BUTTON
  const navigateToAddPage = function () {
    if (page) {
      navigate(`${page}/create`, { state });
    } else {
      navigate(`/${entity}/create`);
    }
  };

  // LAYOUT
  return (
    <div className={`flex ${style.tableHeader}`}>
      <h3>{text}</h3>
      <div className={`flex ${style.tableHeaderFilters}`}>
        <div className={style.searchBar}>
          <SearchInput />
        </div>
        <ButtonNew
          text={t("filter")}
          className="button_white"
          icon="icon-filter"
        />
        {buttonText && (
          <div className={style.tableHeaderFiltersButton}>
            <ButtonNew
              text={buttonText}
              className="button_gradient"
              icon="icon-add-square"
              onClick={navigateToAddPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// EXPORT
export default TableHeader;
