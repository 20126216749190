import { Route, Routes, useLocation, useNavigate } from "react-router";
import "./App.css";
import "./assets/fonts/icomoon-icons/style.css";
import { LoginContainer } from "./pages/Login/LoginContainer";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  verifyToken,
  refreshTokenThunk,
  setTokenVerifiedActionCreator,
} from "./redux/authReducer";
import { RegistrationContainer } from "./pages/Registration/RegistrationContainer";
import MainLayout from "./pages/MainLayout/MainLayout";
import { CompaniesContainer } from "./pages/Companies/CompaniesContainer";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import Test from "./pages/Test/Test";
import Tests from "./pages/Tests/Tests";
import TestsView from "./pages/Tests/View/TestsView";
import TestsCreate from "./pages/Tests/Create/TestsCreate";
import TestsEdit from "./pages/Tests/Edit/TestsEdit";
import TestsAssign from "./pages/TestsAssign/TestsAssign";
import CompaniesView from "./pages/Companies/View/CompaniesView";
import CompaniesEdit from "./pages/Companies/Edit/CompaniesEdit";
import CompaniesCreate from "./pages/Companies/Create/CompaniesCreate";
import Staff from "./pages/Staff/Staff";
import Departments from "./pages/Staff/Departments/Departments";
import StaffCreate from "./pages/Staff/Create/StaffCreate";
import StaffView from "./pages/Staff/View/StaffView";
import StaffEdit from "./pages/Staff/Edit/StaffEdit";
import Analytics from "./pages/Analytics/Analytics";
import { useTranslation } from "react-i18next";

function App({ verifyToken, refreshTokenThunk, role }) {
  const { i18n } = useTranslation();

  // USE NAVIGATE
  const navigate = useNavigate();

  const { isTokenVerified } = useSelector((state) => state.users);

  // USE LOCATION
  const location = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const candidateToken = query.get("token");

  // USE EFFECT
  useEffect(() => {
    if (candidateToken) {
      return;
    }
    // GETTING TOKEN FROM LOCAL STORAGE
    const token = localStorage.getItem("token");

    // VERIFYING IF IT IS VALID
    verifyToken(token, navigate, location.pathname);
  }, [verifyToken, navigate, location.pathname]);
  useEffect(() => {
    if (candidateToken) {
      return;
    }
    // GETTING REFRESH TOKEN FROM LOCAL STORAGE
    const refresh = localStorage.getItem("refresh");
    isTokenVerified && !!refresh && refreshTokenThunk(refresh, navigate);
    const intervalId = setInterval(() => {
      refreshTokenThunk(refresh, navigate);
    }, 4 * 60 * 1000); // 4 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [refreshTokenThunk, navigate, isTokenVerified]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("locale") || "en";
    i18n.changeLanguage(savedLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CHECKING THE ROLE OF THE USER
  const isSuperUser = role === "super";
  const isAdmin = role === "admin";
  const isModerator = role === "moderator";
  const isUser = role === "user";

  // LAYOUT
  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/registration" element={<RegistrationContainer />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/*"
          element={
            <MainLayout>
              <Routes>
                {/* ANALYTICS */}
                {isSuperUser && <Route path="/" element={<Analytics />} />}

                {/* COMPANIES */}
                {isSuperUser && (
                  <Route path="/companies" element={<CompaniesContainer />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/companies/:id" element={<CompaniesView />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route
                    path="/companies/edit/:id"
                    element={<CompaniesEdit />}
                  />
                )}
                {isSuperUser && (
                  <Route
                    path="/companies/create"
                    element={<CompaniesCreate />}
                  />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/companies/:id/staff" element={<Staff />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route
                    path="/companies/:id/departments"
                    element={<Departments />}
                  />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route
                    path="/companies/:id/staff/create"
                    element={<StaffCreate />}
                  />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route
                    path="/companies/:companyId/staff/:staffId"
                    element={<StaffView />}
                  />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route
                    path="/companies/:companyId/staff/:staffId/edit"
                    element={<StaffEdit />}
                  />
                )}

                {/* PROFILE */}
                {isUser && <Route path="/" element={<StaffView />} />}
                {isUser && <Route path="/edit" element={<StaffEdit />} />}

                {/* TESTS */}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/tests/assign" element={<TestsAssign />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/tests" element={<Tests />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/tests/:id" element={<TestsView />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/tests/create" element={<TestsCreate />} />
                )}
                {(isSuperUser || isAdmin || isModerator) && (
                  <Route path="/tests/edit/:id" element={<TestsEdit />} />
                )}
                <Route path="/password/reset" element={<PasswordReset />} />
                <Route path="/test/" element={<Test />} />
              </Routes>
            </MainLayout>
          }
        />
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
  };
};

export default connect(mapStateToProps, { verifyToken, refreshTokenThunk })(
  App
);
