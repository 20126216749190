import { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./CompaniesCreate.module.css";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { useNavigate } from "react-router";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import SelectComponent from "../../../components/functional/SelectComponent/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import { getUsers } from "../../../redux/usersReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import FileDrop from "../../../components/functional/FileDrop/FileDrop";
import { setImage } from "../../../utils/imageUpload/setImage";
import Input from "../../../components/UI/Input/Input";
import { errorToast } from "../../../utils/toasts/toasts";
import { createCompany } from "../../../redux/companiesReducer";
import { useTranslation } from "react-i18next";

// COMPANY CREATE PAGE
const CompaniesCreate = () => {
  const { t } = useTranslation();

  // USE SELECTOR
  const { isLoading } = useSelector((state) => state.preloader);
  const { users } = useSelector((state) => state.users);

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE STATE
  const [company, setCompany] = useState({
    name: "",
    adminId: null,
    email: "",
    logo: null,
  });
  const [usersSelect, setUsersSelect] = useState([]);

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    dispatch(startLoadingActionCreator());
    dispatch(getUsers());
  }, [dispatch]);
  useEffect(() => {
    if (users.length) {
      setUsersSelect(
        users.map((user) => ({
          label: user.name,
          value: { id: user.id, email: user.email },
        }))
      );
    }
  }, [users?.length]);

  const links = [
    {
      path: "/companies",
      icon: "icon-buildings-2",
      current: false,
      text: t("companies"),
    },
    {
      image: company?.logo,
      current: false,
      text: company?.name || t("companyName"),
    },
    {
      path: "/companies/create",
      icon: "icon-add-square",
      current: true,
      text: t("companyCreation"),
    },
  ];

  // LINKS ARRAY FOR NAVIGATION BAR
  const linksNavigation = [
    {
      image: company?.logo,
      text: t("company"),
      current: true,
      path: "/companies",
    },
  ];

  // HANDLING IMAGE
  const handleLogo = (file) => {
    setCompany({
      ...company,
      logo: file,
    });
    setImage(file, "imagePreview");
    setImage(file, "crumbsImage");
    setImage(file, "crudImage");
  };

  // ON COVER IMAGE CHANGE
  const onLogoChange = (event) => {
    const file = event?.target?.files[0];
    handleLogo(file);
  };
  // ON DROP FILES EVENT
  const onDropFiles = (files) => {
    const file = files[0];
    handleLogo(file);
  };

  // BUTTONS FOR HEADER
  const buttonsHeader = [
    {
      text: t("back"),
      className: "button_transparent",
      onClick: () => {
        navigate("/companies");
      },
    },
    {
      text: t("save"),
      className: "button_gradient",
      onClick: () => {
        const formData = new FormData();
        if (!company?.adminId || !company?.name) {
          errorToast(t("someDataIsMissing"));
          return;
        }
        // LOGIC TO GET DATE A YEAR LATER FROM NOW
        const currentDate = new Date();
        const oneYearLater = new Date(currentDate);
        oneYearLater.setFullYear(currentDate.getFullYear() + 1);
        const formattedDate = oneYearLater.toISOString();
        formData.append("admin", company.adminId);
        formData.append("email", company.email);
        formData.append("name", company.name);
        formData.append("active_till", formattedDate);
        if (company?.logo) {
          formData.append("logo", company.logo);
        }
        dispatch(createCompany(formData)).then((response) => {
          navigate("/companies");
        });
      },
    },
  ];

  // ELEMENTS FOR CREATING TEST
  const elements = [
    {
      label: t("logo"),
      isFullWidth: true,
      item: (
        <FileDrop
          onChange={onLogoChange}
          onDropFiles={onDropFiles}
          image={company?.logo}
        />
      ),
    },
    {
      label: t("companyName"),
      item: (
        <Input
          value={company?.name}
          type="text"
          id="companyName"
          onChange={(event) => {
            setCompany({
              ...company,
              name: event.target.value,
            });
          }}
          placeholder={t("companyName")}
        />
      ),
    },
    {
      label: t("admin"),
      item: (
        <SelectComponent
          value={usersSelect?.find(
            (user) => user?.value?.id === company?.adminId
          )}
          onChange={(event) => {
            setCompany({
              ...company,
              adminId: event.value.id,
              email: event.value.email,
            });
          }}
          options={usersSelect}
        />
      ),
    },
  ];

  // LOADING...
  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.create}`}>
      <BreadCrumbs links={links} />
      <div className={`flex ${style.createMain}`}>
        <NavigationBar links={linksNavigation} buttons={[]} />
        <div className={`flex__column ${style.createMainRightside}`}>
          <CRUDHeader
            image={company?.logo}
            name={company?.name || t("naming")}
            buttons={buttonsHeader}
          />
          <InfoBlock
            heading={t("companyData")}
            isEditMode={true}
            values={elements}
          />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default CompaniesCreate;
