import { Form, Field } from "react-final-form";
import { loginValidate } from "../../utils/formValidators/loginValidate";
import style from "./Login.module.css";
import { useNavigate } from "react-router";
import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";
import { useTranslation } from "react-i18next";
import LoginHeader from "./LoginHeader/LoginHeader";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import termsOfServiceDoc from "../../assets/documents/terms_of_service_No_Hunt.pdf";
import privacyPolicyDoc from "../../assets/documents/privacy_policy_No_Hunt.pdf";
import AuthorizationWrapper from "../AuthorizationWrapper/AuthorizationWrapper";

// LOGIN FORM
const LoginForm = ({ login }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");

  // HANDLE SUBMIT
  const onSubmit = function (values) {
    login(values.email, values.password, navigate);
  };

  const changeType = function () {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  // LAYOUT
  return (
    <Form
      onSubmit={onSubmit}
      validate={loginValidate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={`flex__column ${style.loginForm}`}>
            <div className={`flex__column ${style.loginFormInputs}`}>
              <div className="flex__column">
                <label className="text__label">{t("email")}</label>
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <input
                      {...input}
                      type="email"
                      placeholder="abc@abc.com"
                      className={`input ${style.loginInput} ${
                        meta.touched && meta.error ? "input_error" : ""
                      }`}
                    />
                  )}
                />
              </div>
              <div className="flex__column">
                <label className="text__label">{t("password")}</label>
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div
                      className={`input password-input-password ${
                        style.loginInput
                      } ${meta.touched && meta.error ? "input_error" : ""}`}
                    >
                      <input
                        {...input}
                        type={passwordType}
                        placeholder="**********"
                        className="input_password"
                      />
                      <i
                        className={`${
                          passwordType === "password"
                            ? "icon-eye-off"
                            : "icon-eye"
                        } input-icon`}
                        onClick={changeType}
                      />
                    </div>
                  )}
                />
              </div>
              <NavLink
                to={"/password-reset"}
                className={style.loginForgotPassword}
              >
                {t("forgotPassword")}
              </NavLink>
            </div>
            <ButtonNew
              text={t("login")}
              className="button_gradient"
              type="submit"
            />
          </div>
        </form>
      )}
    />
  );
};

// LOGIN PAGE
const Login = ({ login }) => {
  const { t } = useTranslation();
  const footerTexts = (
    <div className={`flex__column ${style.loginPageTexts}`}>
      <p className={style.loginPageTextsText}>
        {t("haveNoAccount")}{" "}
        <a
          href="https://nohunt.uz/registration"
          className={style.loginPageTextsLink}
        >
          {t("register")}
        </a>
      </p>
      <p className={style.loginPageTextsText}>
        {t("haveALookAt")}{" "}
        <a
          href={termsOfServiceDoc}
          className={style.loginPageTextsDocsLink}
          target="blank"
        >
          {t("serviceTerms")}
        </a>{" "}
        {t("and")}{" "}
        <a
          href={privacyPolicyDoc}
          target="blank"
          className={style.loginPageTextsDocsLink}
        >
          {t("confidentialityPolicy")}
        </a>
      </p>
    </div>
  );

  return (
    <AuthorizationWrapper footerTexts={footerTexts}>
      <div className={`flex__column ${style.loginWrapperForm}`}>
        <div className={style.loginWrapperHeading}>
          <h2 className={style.loginHeading}>{t("greetings")}</h2>
          <p className={style.loginInstruction}>{t("enterDataToLogin")}</p>
        </div>
        <LoginForm login={login} />
      </div>
    </AuthorizationWrapper>
  );
};

export default Login;
