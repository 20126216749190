import { useEffect, useState } from "react";
import ChoiceTab from "../../components/functional/ChoiceTab/ChoiceTab";
import style from "./Analytics.module.css";
import Graph from "./Graph/Graph";
import ScatterChartComponent from "./ScatterChart/ScatterChart";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../redux/preloaderReducer";
import { getCompanies } from "../../redux/companiesReducer";
import Preloader from "../../components/UI/Preloader/Preloader";
import { getRandomInteger } from "../../utils/getRandomInteger/getRandomInteger";

// ANALYTICS PAGE
const Analytics = () => {
  // USE SELECTOR
  const { companies } = useSelector((state) => state.companies);
  const { isLoading } = useSelector((state) => state.preloader);
  const { isTokenVerified } = useSelector((state) => state.users);

  // USE DISPATCH
  const dispatch = useDispatch();

  // USE STATE
  // STATE FOR PERIOD OF TIME FOR GRAPH
  const [period, setPeriod] = useState("year");
  // STATE FOR SCATTERED CHART DATA
  const [scatteredData, setScatteredData] = useState([]);

  // USE EFFECT
  // GETTING COMPANIES FOR SCATTER CHART
  useEffect(() => {
    if (isTokenVerified) {
      dispatch(startLoadingActionCreator());
      dispatch(getCompanies());
    }
  }, [dispatch, isTokenVerified]);
  // SETTING SCATTERED CHART DATA AS SOON AS WE RECEIVE COMPANIES FROM BACKEND
  useEffect(() => {
    if (companies.length) {
      // MAPPING COMPANIES ARRAY INTO DATA REQUIRED FOR SCATTERED CHART
      const scatteredCompanies = companies.map((company) => ({
        x: getRandomInteger(1, 100),
        y: company.users.length,
        name: company.name,
        logoUrl: company.logo,
      }));

      // SETTING LOCAL STATE OF DATA TO CREATED ARRAY
      setScatteredData(scatteredCompanies);
    }
  }, [companies.length]);

  if (isLoading) return <Preloader />;

  // LAYOUT
  return (
    <div className={`flex__column ${style.analytics}`}>
      <div className={`flex__column ${style.analyticsGraph}`}>
        <div className={`flex ${style.analyticsGraphHeader}`}>
          <h2>Добавленные компании</h2>
          <div className={`flex ${style.analyticsGraphHeaderTabs}`}>
            <ChoiceTab
              isActive={period === "year"}
              text="12 месяцев"
              onClick={() => setPeriod("year")}
            />
            <ChoiceTab
              isActive={period === "month"}
              text="30 дней"
              onClick={() => setPeriod("month")}
            />
            <ChoiceTab
              isActive={period === "week"}
              text="7 дней"
              onClick={() => setPeriod("week")}
            />
            <ChoiceTab
              isActive={period === "day"}
              text="24 часа"
              onClick={() => setPeriod("day")}
            />
          </div>
        </div>
        <div className={style.analyticsGraphBlock}>
          <Graph />
        </div>
      </div>
      <div className={`flex ${style.analyticsFlex}`}>
        <div className={`flex__column ${style.analyticsFlexScatter}`}>
          <h2 className={style.analyticsFlexScatterHeading}>
            Сотрудники в компаниях
          </h2>
          <ScatterChartComponent data={scatteredData} />
        </div>
      </div>
    </div>
  );
};

// EXPORT
export default Analytics;
